import React, {useEffect} from "react"
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom"
import About from "./About"
import MainContent from "./MainContent"
import Contact from "./Contact"
import Portfolio from "./Portfolio"
import Sentinels from "./sentinels/Sentinels"
import SentinelCollection from "./sentinels/collection/SentinelCollection"
import Traversal from "./sentinels/collection/Traversal"
import Solace from "./sentinels/collection/SolaceAndDiscovery"
import Arise from "./sentinels/collection/Arise"
import Juniper from "./sentinels/collection/Juniper"
import Mirror from "./sentinels/collection/Mirror"
import Passage from "./sentinels/collection/Passage"
import FortyForth from "./sentinels/collection/FortyForthAndSecond"
import Petrichor from "./sentinels/collection/Petrichor"
import PersistenceOfDreams from "./sentinels/collection/PersistenceOfDreams"
import Morvan from "./sentinels/collection/Morvan"
import SleepingGiants from "./sentinels/collection/SleepingGiants"
import Maelstrom from "./sentinels/collection/Maelstrom"
import Error from "./Error"
import {AnimatePresence} from 'framer-motion/dist/framer-motion'
import ScrollToTop from './ScrollToTop'

export default function AnimatedRoutes(){
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <ScrollToTop />
      <Routes location={location} key={location.pathname}>
        <Route index element={<MainContent />} />
        <Route path="/" element={<MainContent />} />
        <Route path="/about" element={<About />}  />
        <Route path="/contact" element={<Contact />} />
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/sentinels" element={<Sentinels />} />
          <Route path="/sentinels/traversal" element={<Traversal />} />
          <Route path="/sentinels/solace-and-discovery" element={<Solace />} />
          <Route path="/sentinels/arise" element={<Arise />} />
          <Route path="/sentinels/juniper" element={<Juniper />} />
          <Route path="/sentinels/mirror" element={<Mirror />} />
          <Route path="/sentinels/passage" element={<Passage />} />
          <Route path="/sentinels/44th-and-2nd" element={<FortyForth />} />
          <Route path="/sentinels/petrichor" element={<Petrichor />} />
          <Route path="/sentinels/persistence-of-dreams" element={<PersistenceOfDreams />} />
          <Route path="/sentinels/morvan" element={<Morvan />} />
          <Route path="/sentinels/sleeping-giants" element={<SleepingGiants />} />
          <Route path="/sentinels/maelstrom" element={<Maelstrom />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </AnimatePresence>
  )
}
