import React, { useRef, useState } from "react"
import useSlider from "./UseSlider"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons"
import {motion} from 'framer-motion/dist/framer-motion'

const Slider = ({ images }) => {
  const slideImage = useRef(null)
  const nextImage = useRef(null)
  const { goToPreviousSlide, goToNextSlide, jumpToSlide } = useSlider(
    slideImage,
    nextImage,
    images
  )
  const i = images

  const [showModal, setShowModal] = useState(false);

  const openModal2 = () => {
    setShowModal(prev => !prev);
  }

  return (
    <>
    <motion.div initial={{opacity:0}} animate={{opacity:1}} transition={{duration:1}} className="slider" ref={slideImage}>
      <div className="slider--content">
        <div onClick={goToPreviousSlide} className="slider__btn-left">
          <FontAwesomeIcon icon={faAngleLeft}></FontAwesomeIcon> Previous
        </div>
        <div className="next-item-container">
          <p>
            Keep checking out Matt Wilson's work
            <span>Next image</span>
          </p>
          <button onClick={goToNextSlide} className="slider__btn-right">
            <div ref={nextImage} className="next-image-box"></div>
          </button>
        </div>
      </div>
      <div className="enter-sentinels-link">
        <div>
          <span onClick={() => openModal2()} className="view-full-gallery">View Full Gallery</span>
        </div>
        <p>Click the next image or previous link to view more of Matt Wilson/BunnyLever’s portfolio</p>
      </div>
    </motion.div>


      {showModal ?
        <>
          <motion.div className="grid-view" initial={{opacity:0}} animate={{opacity:1}} whileInView={{opacity: 1}} exit={{opacity:0}} transition={{duration:1}}>
            <div className="close-grid-view" onClick={() => setShowModal(prev => !prev)}><span>X</span> Close Gallery</div>
            <ul>
            {i.map((item, index) => (
              <li key={index}>
                <div className="image-container">
                  <img src={item.src} onClick={() => {jumpToSlide({index}); openModal2()}} key={index} />
                </div>
              </li>
            ))}
            </ul>
          </motion.div>
        </> : null
      }
    </>
  )
}

export default Slider
