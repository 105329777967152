import React from "react"
import SentinelComponent from "./SentinelComponent"
import arise from "../../../images/arise.png"

export default function Arise(){
    return(
        <SentinelComponent
          index="3"
          link="/sentinels/arise"
          src={arise}
          alt="Arise - Sentinels #3 by Matt Wilson"
          name="Arise"
          description="​COVID froze the world around us. Together we sunk into a collective hibernation, closed off from each other and ourselves. Finally as the sickness thawed and we began to search for the new version of our lives we lifted our faces into the sunlight and felt a familiar warmth. Even in the frozen, dark and cold places that many of us went and perhaps still are, we rise together stronger."
          urlExternal="https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/3"
          next="/sentinels/juniper"
          prev="/sentinels/solace-and-discovery"
          classN="arise"
        />
    )
}
