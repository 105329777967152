import React from "react"
import SentinelComponent from "./SentinelComponent"
import petrichor from "../../../images/petrichor.jpg"

export default function Petrichor(){
    return(
        <SentinelComponent
          index="8"
          link="/sentinels/petrichor"
          src={petrichor}
          alt="Petrichor - Sentinels #8 by Matt Wilson"
          name="Petrichor"
          description="A large cloud formation, covered in cloth, rises over a valley split by a creek and covered in mist​"
          urlExternal="https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/8"
          next="/sentinels/persistence-of-dreams"
          prev="/sentinels/44th-and-2nd"
          classN="petrichor"
        />
    )
}
