import React from "react"
import SentinelComponent from "./SentinelComponent"
import morvan from "../../../images/morvan.jpg"

export default function Morvan(){
    return(
        <SentinelComponent
          index="10"
          link="/sentinels/morvan"
          src={morvan}
          alt="Morvan - Sentinels #10 by Matt Wilson"
          name="Morvan"
          description="Two clouds, draped in cloth, drift overtop of a cabin, surrounded by mist and a lush green landscape"
          urlExternal="https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/10"
          next="/sentinels/sleeping-giants"
          prev="/sentinels/persistence-of-dreams"
          classN="morvan"
        />
    )
}
