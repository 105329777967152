import React, {useEffect, useState, useRef} from "react"
import mattWilson from "../images/Matt-Wilson-BunnyLever.png"
import volumetricCloudsCloudscapes from "../images/volumetric-clouds-cloudscapes.png"
import disney from "../images/disney-logo@2x.png"
import pixar from "../images/pixar-logo@2x.png"
import bluesky from "../images/blue-sky-logo@2x.png"
import iceAge from "../images/ice-age-logo@2x.png"
import rio from "../images/rio-logo@2x.png"
import rio2 from "../images/rio2@2x.png"
import horton from "../images/horton-hears-a-who-logo@2x.png"
import lightyear from "../images/lightyear-logo@2x.png"
import turningRed from "../images/turning-red-logo@2x.png"
import spiesInDisguise from "../images/spies-in-disguise-logo@2x.png"
import epic from "../images/epic-logo@2x.png"
import nimona from "../images/nimona@2x.png"
import turningRedPoster from "../images/turning-red.png"
import lightyearPoster from "../images/lightyear.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight, faQuoteLeft } from "@fortawesome/free-solid-svg-icons"
import mattWilsonClouds from "../images/matt-wilson-clouds.png"
import miniClouds from "../images/mini-clouds-matt-wilson.png"
import arise from "../images/arise-frame.png"
import traversal from "../images/traversal-frame.png"
import juniper from "../images/juniper-frame.png"
import solaceDiscovery from "../images/solace-discovery-frame.png"
import mirror from "../images/mirror-frame.png"
import passage from "../images/passage-frame.png"
import petrichor from "../images/petrichor-frame.png"
import houdini from "../images/houdini-logo@2x.png"
import bunnylever from "../images/bunnylever2.png"
import {motion, useSpring} from 'framer-motion/dist/framer-motion'
import {Link} from "react-router-dom";

export default function About(){

    useEffect(() => {
        if(document.body.classList.contains('hidden')) {
          document.body.classList.remove("hidden");
        }
        if(!document.body.classList.contains("show"))
          document.body.classList.add("show");

        document.getElementsByTagName('body')[0].style.background = "#000";
        setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);

        if(document.getElementsByTagName('footer')[0].classList.contains('fixed')) {
          document.getElementsByTagName('footer')[0].classList.remove("fixed");
        }
        document.getElementsByTagName('footer')[0].classList.add("relative");

      return () => {
      };
    }, []);

    const [width, setWidth] = useState(0);
    const carousel = useRef();

    // scroll offset
    const {scrollOffesetYSpring, scollOffsetYApi} = useSpring(() => ({
      yOffset: 0,
    }));

    // Scroll to sections (clouds in hero section)
    const bio = useRef(null)
    const executeScroll_bio = () => bio.current.scrollIntoView({behavior: "smooth"})

    const career = useRef(null)
    const executeScroll_career = () => career.current.scrollIntoView({behavior: "smooth"})

    const process = useRef(null)
    const executeScroll_process = () => process.current.scrollIntoView({behavior: "smooth"})

    const whatsNext = useRef(null)
    const executeScroll_whatsNext = () => whatsNext.current.scrollIntoView({behavior: "smooth"})

    const faq = useRef(null)
    const executeScroll_faq = () => faq.current.scrollIntoView({behavior: "smooth"})

    return (
          <div className="about">
            <div className="hero hero-about">
              <div className="hero-content">
                <motion.h2 initial={{x: 75, opacity: 0}} whileInView={{x:0, opacity: 1}} transition={{duration: 2, repeatType: "reverse"}}>aka BunnyLever</motion.h2>
                <h1 className="h1-smaller about-header">
                  <motion.span initial={{x: 75, opacity: 0}} whileInView={{x:0, opacity: 1}} transition={{duration: 1, repeatType: "reverse"}} className="txt-white">Matt Wilson </motion.span>
                  <motion.span initial={{x: 75, opacity: 0}} whileInView={{x:0, opacity: 1}} transition={{duration: 1, delay: 0.3, repeatType: "reverse"}} className="txt-right">- lead techincal </motion.span>
                  <motion.span initial={{x: 75, opacity: 0}} whileInView={{x:0, opacity: 1}} transition={{duration: 1, delay: 0.6, repeatType: "reverse"}}>artist in film & </motion.span>
                  <motion.span initial={{x: 75, opacity: 0}} whileInView={{x:0, opacity: 1}} transition={{duration: 1, delay: 0.9, repeatType: "reverse"}} className="txt-right">animation</motion.span>
                </h1>

                <motion.div className="cloud cloud1" initial={{scale:0}} whileInView={{scale:1}} transition={{duration:1, repeatType: "reverse"}} onClick={executeScroll_bio}>
                  <span>Bio</span>
                </motion.div>
                <motion.div className="cloud cloud2" initial={{scale:0}} whileInView={{scale:1}} transition={{duration:1, delay:0.3, repeatType: "reverse"}} onClick={executeScroll_career}>
                  <span>Career</span>
                </motion.div>
                <motion.div className="cloud cloud3" initial={{scale:0}} whileInView={{scale:1}} transition={{duration:1, delay: 0.7, repeatType: "reverse"}} onClick={executeScroll_process}>
                  <span>Process</span>
                </motion.div>
                <motion.div className="cloud cloud4" initial={{scale:0}} whileInView={{scale:1}} transition={{duration:1, delay:1, repeatType: "reverse"}} onClick={executeScroll_whatsNext}>
                  <span>What's Next?</span>
                </motion.div>
                <motion.div className="cloud cloud5" initial={{scale:0}} whileInView={{scale:1}} transition={{duration:1, delay:1.3, repeatType: "reverse"}} onClick={executeScroll_faq}>
                  <span>FAQ</span>
                </motion.div>
              </div>
              <div className="hero-content-right">
                <motion.img src={mattWilson} alt="Matt Wilson" initial={{opacity:0, x:-100}} whileInView={{opacity:1, x:0}} transition={{duration:3}} />
              </div>
            </div>

            <div className="about-section about-s1" ref={bio}>
              <motion.h2 initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1, repeatType: "reverse"}}>20+ <span>years</span></motion.h2>
              <motion.p initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1, delay:0.3, repeatType: "reverse"}} className="text-small"> Matthew S. Wilson has been a professional artist in the film and animation industry for more than 20 years. He received his B.F.A from the Ringling School of Art and Design, where he specialized in computer animation and fine arts.</motion.p>
            </div>

            <div className="about-section about-s2">
              <div>
                <img src={volumetricCloudsCloudscapes} alt="Volumetric Clouds & Cloudscapes" />
                <h2>Volumetric Clouds <span>& Cloudscapes</span></h2>
              </div>
              <motion.p initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1, repeatType: "reverse"}} className="text-med">
                For several years post-graduation, he focused on showing and selling classical narrative paintings in several galleries in Atlanta, GA, and Highpoint, NC. It was in these early days that the works began to explore clouds as part of a deeper landscape.
              </motion.p>
              <motion.p initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1, delay:0.3, repeatType: "reverse"}} className="text-med">
                His work on crafting volumetric clouds and cloudscapes has been a central point of development at studios such as Pixar and Bluesky studios. As a lead technical artist he has maintained a pivotal role in developing the use of 3d volumetric clouds in cinema.
              </motion.p>
            </div>

            <div className="about-section about-s3" ref={career}>
              <motion.h3 initial={{opacity:0, y:100}} whileInView={{opacity:1, y:0}} transition={{duration:1, repeatType: "reverse"}}>Artist <span>Matt Wilson/Bunnylever</span> has worked on</motion.h3>
              <motion.h2 initial={{opacity:0, y:100}} whileInView={{opacity:1, y:0}} transition={{duration:1, delay: 0.2, repeatType: "reverse"}}>Films that Shaped <span className="txt-default">our childhoods.</span></motion.h2>
              <motion.p initial={{opacity:0, y:100}} whileInView={{opacity:1, y:0}} transition={{duration:1, delay: 0.4, repeatType: "reverse"}}>This is an abbreviated list of projects Matt has worked on</motion.p>
              <div className="logos1 logos">
                <motion.img initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1, delay: 0.6, repeatType: "reverse"}} src={disney} alt="Disney" />
                <motion.img initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1, delay: 0.8, repeatType: "reverse"}} src={pixar} alt="Pixar Animation Studios" />
                <motion.img initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1, delay: 1, repeatType: "reverse"}} src={bluesky} alt="BlueSky Studios" />
              </div>
              <div className="logos2 logos">
                <ul>
                  <li><motion.img initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1, delay: 1.2, repeatType: "reverse"}} src={iceAge} alt="Ice Age" /></li>
                  <li><motion.img initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1, delay: 1.4, repeatType: "reverse"}} src={rio} alt="Rio" /></li>
                  <li><motion.img initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1, delay: 1.6, repeatType: "reverse"}} src={horton} alt="Dr. Suess: Horton Hears a Who" /></li>
                  <li><motion.img initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1, delay: 1.8, repeatType: "reverse"}} src={lightyear} alt="Lightyear" /></li>
                  <li><motion.img initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1, delay: 2, repeatType: "reverse"}} src={turningRed} alt="Turning Red" /></li>
                  <li><motion.img initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1, delay: 2.2, repeatType: "reverse"}} src={spiesInDisguise} alt="Spies in Disguise" /></li>
                  <li><motion.img initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1, delay: 2.4, repeatType: "reverse"}} src={epic} alt="epic" /></li>
                </ul>
              </div>
            </div>

            <div className="about-section about-s4">
              <div>
                <h2>
                  <motion.span initial={{opacity:0, x: 200}} whileInView={{opacity:1, x:0}} transition={{duration:1, repeatType: "reverse"}} className="word1">Creating </motion.span>
                  <motion.span initial={{opacity:0, x:-200}} whileInView={{opacity:1, x:0}} transition={{duration:1, delay: 0.2, repeatType: "reverse"}} className="word2">Volumetric Clouds & </motion.span>
                  <motion.span initial={{opacity:0, x:-200}} whileInView={{opacity:1, x:0}} transition={{duration:1, delay: 0.4, repeatType: "reverse"}}>Cloudscapes </motion.span>
                  <motion.span initial={{opacity:0, x: 200}} whileInView={{opacity:1, x:0}} transition={{duration:1, delay: 0.6, repeatType: "reverse"}} className="word4">For Films </motion.span>
                  <motion.strong initial={{opacity:0, x:200}} whileInView={{opacity:1, x:0}} transition={{duration:1, delay: 1, repeatType: "reverse"}}>Such As</motion.strong>
                </h2>
              </div>
              <motion.div ref={carousel} className="carousel">
                <motion.ul className="inner-carousel items poster-carousel"  drag="x" dragConstraints={{right: 0, left: -width}}>
                  <motion.li initial={{opacity:0, x:100}} whileInView={{opacity:1, x:0}} transition={{duration:1, delay: 0.2, repeatType: "reverse"}}><img src={turningRedPoster} alt="Turning Red" /></motion.li>
                  <motion.li initial={{opacity:0, x:100}} whileInView={{opacity:1, x:0}} transition={{duration:1, delay: 0.5, repeatType: "reverse"}}><img src={lightyearPoster} alt="Lightyear" /></motion.li>
                </motion.ul>
              </motion.div>
            </div>

            <div className="about-section about-s5">
              <table>
                <tr>
                  <td><h3>Pixar</h3></td>
                  <td><h4>Cloud/matte paint/Set extension, light/comp</h4></td>
                  <td>
                    <ul className="about-pixar">
                      <li><img src={lightyear} alt="Lightyear Logo" /></li>
                      <li><img src={turningRed} alt="Turning Red Logo" /></li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td><h3>BlueSky Studios</h3></td>
                  <td><h4>Light/Comp lead</h4></td>
                  <td>
                    <ul>
                      <li><img src={rio} alt="Rio 2 Logo" /></li>
                      <li><img src={nimona} alt="Nimona Logo" /></li>
                      <li><img src={epic} alt="Epic Logo" /></li>
                      <li><img src={spiesInDisguise} alt="Spies In Disguise Logo" /></li>
                      <li><img src={iceAge} alt="Ice Age Logo" /></li>
                      <li><img src={rio2} alt="Rio Logo" /></li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td><h3>CHRLX</h3></td>
                  <td><h4>3D Creative Director</h4></td>
                  <td>

                  </td>
                </tr>
              </table>
            </div>

            <div className="about-section about-s6" ref={whatsNext}>
              <motion.h3 initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1, delay: 1.2, repeatType: "reverse"}}>What's Next?</motion.h3>
              <motion.h2 initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:1, delay: 0.8, repeatType: "reverse"}}>Introducing <span>Sentinels</span></motion.h2>
              <div><motion.img initial={{opacity:0, scale:0.6}} whileInView={{opacity:1, scale:1}} transition={{duration:2, repeatType: "reverse", ease: 'easeOut'}} src={mattWilsonClouds} alt="Matt Wilson" /></div>
              <motion.div initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration:1, delay: 1, repeatType: "reverse"}}><Link to="/sentinels"><span>View Sentinels Story & Collection <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></span></Link></motion.div>
              <motion.p initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration:1, delay: 0.4, repeatType: "reverse"}}>Or scroll down to see more</motion.p>
            </div>

            <div className="about-section about-s7">
              <motion.p initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration:1, repeatType: "reverse"}}>
                The newest personal works seek to take that knowledge, acquired across the years of production, and return them to the narrative and surreal images that began in his early career.
              </motion.p>
              <motion.p initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration:1, delay: 0.3, repeatType: "reverse"}} className="txt-white">
                “Sentinels” is the first complete collection of 12 images that uses his proprietary cloud building methods.
              </motion.p>

              <motion.div className="s7-buttons" initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration:1, delay: 0.6, repeatType: "reverse"}}>
                <Link to="/sentinels">
                  <img src={miniClouds} alt="Matt Wilson's Sentinels Collection" />
                  <div>Explore <br /><span>Sentinels <br />Part 1</span></div>
                  <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
                </Link>
                <a href="https://opensea.io/collection/sentinels-v3" rel="noreferrer noopener" target="_blank" className="link-right">
                  <div>View Sentinels <br />on OpenSea</div>
                  <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
                </a>
              </motion.div>
            </div>

            <div className="about-section about-frames">
              <div>
                <Link to="/sentinels/passage"><motion.img initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration:1, delay: 0.3, repeatType: "reverse"}} src={passage} alt="Passage by Matt Wilson" /></Link>
                <Link to="/sentinels/traversal"><motion.img initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration:1, delay: 0.8, repeatType: "reverse"}} src={traversal} alt="Traversal by Matt Wilson" /></Link>
                <Link to="/sentinels/juniper"><motion.img initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration:1, delay: 1.4, repeatType: "reverse"}} src={juniper} alt="Juniper by Matt Wilson" /></Link>
                <Link to="/sentinels/arise"><motion.img initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration:1, delay: 2, repeatType: "reverse"}} src={arise} alt="Arise by Matt Wilson" /></Link>
                <Link to="/sentinels/mirror"><motion.img initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration:1, delay: 0.6, repeatType: "reverse"}} src={mirror} alt="Mirror by Matt Wilson" /></Link>
                <Link to="/sentinels/solace-and-discovery"><motion.img initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration:1, repeatType: "reverse", delay: 1.7}} src={solaceDiscovery} alt="Solace & Discovery by Matt Wilson" /></Link>
                <Link to="/sentinels/petrichor"><motion.img initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration:1, delay: 0.9, repeatType: "reverse"}} src={petrichor} alt="Petrichor by Matt Wilson" /></Link>
              </div>

              <motion.p initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration:2, delay: 1.5, repeatType: "reverse"}}>The series pushes to <span>generate imagery</span> at a resolution and <span>level of complexity</span> typically achievable only at the <span>largest studios</span> with the processing power needed to generate these kinds of <span>environments.</span></motion.p>
            </div>

            <div className="about-section about-s8" ref={process}>
              <motion.h3 initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration:1, delay: 1.5, repeatType: "reverse"}}>Process</motion.h3>
              <motion.h2 initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration:1, repeatType: "reverse"}}>Go behind
                <motion.span initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration:1, delay: 0.3, repeatType: "reverse"}} className="txt-default">the scenes</motion.span>
                <motion.span initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration:1, delay: 0.6, repeatType: "reverse"}} className="txt-white revert-default"><small>w/</small>Matt Wilson</motion.span>
              </motion.h2>
              <motion.h4 initial={{opacity: 0}} whileInView={{opacity: 1}} transition={{duration:1, delay: 0.9, repeatType: "reverse"}}>aka BunnyLever</motion.h4>
            </div>

            <div className="about-section about-s9">
              <div>
                <span className="about-s9-1">Beginning <br />back in</span>
                <span className="about-s9-2">2009</span>
                <span className="about-s9-3">ish</span>
                <span className="about-s9-4">when</span>
              </div>
              <div>
                <span className="about-s9-5 ">I</span>
                <div className="about-s9-float">
                  <span className="about-s9-6">began</span>
                  <span className="about-s9-7">shaping and developing</span>
                  <span className="about-s9-8">the skies department</span>
                  <span className="about-s9-7">with my cohorts, Andrew Schneider and Trevor <br />Thomson, my focus shifted to clouds.</span>
                </div>
              </div>
              <div>
                <span className="about-s9-5 smaller">I</span>
                <div className="about-s9-float">
                  <span className="about-s9-9 push-down">have</span>
                  <span className="about-s9-6 push-down2">always</span>
                  <span className="about-s9-9">felt</span>
                </div>
                <div>
                  <p className="about-s9-10">that there was just something about them that resonated with me. They have provided many hours of wide eyed happiness in my life and a tremendous sense of awe. Im consumed with the physics of their formation, their material properties as well as the physics of the way light is transported through the wide variety of mediums that comprise a simple `ole cloud. They are nothing short of magic.</p>
                </div>
              </div>
              <div>
                <p className="about-s9-9 fix-line-height txt-white">All of the elements that create <img src={miniClouds} alt="Matt Wilson's Sentinels Collection" /><span className="off-screen">clouds</span><span className="push-down3"></span>require a <span className="txt-default">TON</span> of complex dynamics <br />and processing time.</p>
              </div>
              <div>
                <div><span className="about-s9-5">I</span></div>
                <div><p className="about-s9-11">currently <span className="txt-white">build</span> all my clouds <br />through a wide variety of <br />simulation methods and artistic <br />embellishments in <img src={houdini} alt="Houdini" /></p></div>
              </div>
            </div>

            <div className="about-section about-s10 about-faq" ref={faq}>
              <h3>FAQ</h3>
              <h2><span>Why</span> BunnyLever?</h2>
              <div className="about-s10-container">
                <div className="about-s10-left">
                  <FontAwesomeIcon icon={faQuoteLeft}></FontAwesomeIcon>
                  <p className="about-s9-7">What is a “bunnylever”??? … ok ok, so when all of this started for me, NFTs and twitter were not really in my life (or anyone’s for that matter). My Son was a newborn little dude, so naturally he & my wife were (and are) the center of my universe. Whenever we would change him he would make this adorable hand gesture like he was pulling a lever. <br /><br />We joked that he was pulling the bunnylever and releasing interdimensional bunnies into our universe. I started making bunny clouds for his room, and when NFTs started and I jumped back onto twitter, I just picked that as my handle… then I found my bored ape and the rest just took its course.
                  </p>
                </div>
                <div className="about-s10-right">
                  <img src={bunnylever} alt="BunnyLever" />
                </div>
              </div>
            </div>
          </div>
    )
}
