import React from "react"
import SentinelComponent from "./SentinelComponent"
import juniper from "../../../images/juniper.png"

export default function Juniper(){
    return(
        <SentinelComponent
          index="4"
          link="/sentinels/juniper"
          src={juniper}
          alt="Juniper - Sentinels #4 by Matt Wilson"
          name="Juniper"
          description="​​Juniper was the name we had dreamt of giving our second child who did not make it into our world. While there is mourning symbolized in the alkaline water, this image is not about loss. It is about strength and healing. This is the Sentinel who guards over my life and protects our family… my wife and partner Alexandra. I made this place to cherish, admire, grow, heal and dream beyond the horizon. Madonna."
          urlExternal="https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/4"
          next="/sentinels/passage"
          prev="/sentinels/arise"
          classN="juniper"
        />
    )
}
