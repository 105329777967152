import React from "react"
import mouseIcon from "../../images/mouse-icon.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import {webTData} from "./WebTData"
import Slider from "./Slider"
import Images from "./WebTData"

export default function WebT(){
  return (
    <>
    <div className="mainContent portfolio web2">
      <Slider images={webTData} />
    </div>
    </>
  )
}
