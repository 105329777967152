import traversal from "../../../images/traversal.png"
import solaceAndDiscovery from "../../../images/solace-and-discovery.png"
import arise from "../../../images/arise.png"
import juniper from "../../../images/juniper.png"
import mirror from "../../../images/mirror.png"
import passage from "../../../images/passage.png"
import fortyForthAndSecond from "../../../images/44th-and-2nd.jpg"
import maelstrom from "../../../images/maelstrom.jpg"
import sleepingGiants from "../../../images/sleeping-giants.jpg"
import morvan from "../../../images/morvan.jpg"
import petrichor from "../../../images/petrichor.jpg"
import persistenceOfDreams from "../../../images/persistence-of-dreams.jpeg"


export const sentinelsCollection = [
  {
    url: '/sentinels/traversal',
    image: traversal,
    alt: 'Traversal - Sentinels #1 by Matt Wilson',
    price: 'Not for sale',
    description: "Please…sit, rest. Speak & be heard. Allow me to introduce you to a mother, father, son, daughter, sister, brother, partner, wild child, friend and listener. The world might seem barren and scorched at times, but here in the shade where these many Sentinels traverse and many paths cross, may you find solace and a place to be renewed.",
    marketplace_link: "https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/1",
    type: '1/1',
    name: 'Traversal',
    key: 'traversal',
  },
  {
    url: '/sentinels/solace-and-discovery',
    image: solaceAndDiscovery,
    alt: 'Solace & Discovery - Sentinels #2 by Matt Wilson',
    price: '7 ETH',
    description: 'We recreate ourselves many times during our lives, constantly pulling down barriers in search of the truth. Perhaps we are fortunate in that we can still recognize the way. Both the route and the walls define us in these cycles. However, when we choose to think outside the box and seek to see potential in its genuine form, we discover that it is actually shapeless.',
    marketplace_link: 'https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/2',
    type: '1/1',
    name: 'Solace & Discovery',
    key: 'solace-discovery'
  },
  {
    url: '/sentinels/arise',
    image: arise,
    alt: 'Arise - Sentinels #3 by Matt Wilson',
    price: '8 ETH',
    description: 'COVID froze the world around us. Together we sunk into a collective hibernation, closed off from each other and ourselves. Finally as the sickness thawed and we began to search for the new version of our lives we lifted our faces into the sunlight and felt a familiar warmth. Even in the frozen, dark and cold places that many of us went and perhaps still are, we rise together stronger.',
    marketplace_link: 'https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/3',
    type: '1/1',
    name: 'Arise',
    key: 'arise'
  },
  {
    url: '/sentinels/juniper',
    image: juniper,
    alt: 'Juniper - Sentinels #4 by Matt Wilson',
    price: 'Not for sale',
    description: 'Juniper was the name we had dreamt of giving our second child who did not make it into our world. While there is mourning symbolized in the alkaline water, this image is not about loss. It is about strength and healing. This is the Sentinel who guards over my life and protects our family… my wife and partner Alexandra. I made this place to cherish, admire, grow, heal and dream beyond the horizon. Madonna.',
    marketplace_link: 'https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/4',
    type: '1/1',
    name: 'Juniper',
    key: 'juniper'
  },
  {
    url: '/sentinels/passage',
    image: passage,
    alt: 'Passage - Sentinels #5 by Matt Wilson',
    price: '8 ETH',
    description: 'Deep in the darkness of Joshua tree, we found a passage in the stars. Sitting there in the cold, together under the light of the Milky way, we laugh and cried and dreamt of the future. We hadn’t realized it then, but his little feet were just on the edge of life. There he stood, listing to the story of our lives together, waiting to become part of it. Waiting for his turn to take our flame and become the guardian of his destiny.',
    marketplace_link: 'https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/5',
    type: '1/1',
    name: 'Passage',
    key: 'passage'
  },
  {
    url: '/sentinels/mirror',
    image: mirror,
    alt: 'Mirror - Sentinels #6 by Matt Wilson',
    price: '25 ETH',
    description: `Mad Song, By William Blake\<br />
                \<br />
                The wild winds weep<br />
                And the night is a-cold;\<br />
                Come hither, Sleep,\<br />
                And my griefs infold:\<br />
                But lo! the morning peeps\<br />
                Over the eastern steeps,\<br />
                And the rustling birds of dawn\<br />
                The earth do scorn.\<br />
                \<br />
                Lo! to the vault\<br />
                Of paved heaven,\<br />
                With sorrow fraught\<br />
                My notes are driven:\<br />
                They strike the ear of night,\<br />
                Make weep the eyes of day;\<br />
                They make mad the roaring winds,\<br />
                And with tempests play.\<br />
                \<br />
                Like a fiend in a cloud,\<br />
                With howling woe,\<br />
                After night I do crowd,\<br />
                And with night will go;\<br />
                I turn my back to the east,\<br />
                From whence comforts have increas’d;\<br />
                For light doth seize my brain\<br />
                With frantic pain.`,
    marketplace_link: 'https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/6',
    type: '1/1',
    name: 'Mirror',
    key: 'mirror'
  },
  {
    url: '/sentinels/44th-and-2nd',
    image: fortyForthAndSecond,
    alt: 'Forty Forth & Second - Sentinels #7 by Matt Wilson',
    price: 'Not for sale',
    description: 'Bound with a black sequin veil a formation of clouds surrounds the Chrysler building',
    marketplace_link: 'https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/7',
    type: '1/1',
    name: '44th & 2nd',
    key: 'forty-forth'
  },
  {
    url: '/sentinels/petrichor',
    image: petrichor,
    alt: 'Petrichor - Sentinels #8 by Matt Wilson',
    price: '8 ETH',
    description: 'A large cloud formation, covered in cloth, rises over a valley split by a creek and covered in mist​',
    marketplace_link: 'https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/8',
    type: '1/1',
    name: 'Petrichor',
    key: 'petrichor'
  },
  {
    url: '/sentinels/persistence-of-dreams',
    image: persistenceOfDreams,
    alt: 'Persistence of Dreams - Sentinels #9 by Matt Wilson',
    price: 'Not for sale',
    description: 'A large cloud, draped in a gold piped cloth moved towards a nearly dead tree in the desert',
    marketplace_link: 'https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/9',
    type: '1/1',
    name: 'Persistence of Dreams',
    key: 'persistence-of-dreams'
  },
  {
    url: '/sentinels/morvan',
    image: morvan,
    alt: 'Morvan - Sentinels #10 by Matt Wilson',
    price: '8 ETH',
    description: 'Two clouds, draped in cloth, drift overtop of a cabin, surrounded by mist and a lush green landscape',
    marketplace_link: 'https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/10',
    type: '1/1',
    name: 'Morvan',
    key: 'morvan'
  },
  {
    url: '/sentinels/sleeping-giants',
    image: sleepingGiants,
    alt: 'Sleeping Giants - Sentinels #11 by Matt Wilson',
    price: '8 ETH',
    description: 'Several cloud formations, covered in cloth hover over the ocean',
    marketplace_link: 'https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/11',
    type: '1/1',
    name: 'Sleeping Giants',
    key: 'sleeping-giants'
  },
  {
    url: '/sentinels/maelstrom',
    image: maelstrom,
    alt: 'Maelstrom - Sentinels #12 by Matt Wilson',
    price: '30 ETH',
    description: '​A massive storm front rises into the air, clinging to a thin, windswept drape',
    marketplace_link: 'https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/12',
    type: '1/1',
    name: 'Maelstrom',
    key: 'maelstrom'
  }
];
