import React from "react"
import SentinelComponent from "./SentinelComponent"
import fortyForth from "../../../images/44th-and-2nd.jpg"

export default function FortyForthAndSecond(){
    return(
        <SentinelComponent
          index="7"
          link="/sentinels/44th-and-2nd"
          src={fortyForth}
          alt="44th & 2nd - Sentinels #7 by Matt Wilson"
          name="44th & 2nd"
          description="​Bound with a black sequin veil a formation of clouds surrounds the Chrysler building"
          urlExternal="https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/7"
          next="/sentinels/petrichor"
          prev="/sentinels/mirror"
          classN="fortyForth"
        />
    )
}
