import React from "react"
import SentinelComponent from "./SentinelComponent"
import persistenceOfDreams from "../../../images/persistence-of-dreams.jpg"

export default function PersistenceOfDreams(){
    return(
        <SentinelComponent
          index="9"
          link="/sentinels/persistence-of-dreams"
          src={persistenceOfDreams}
          alt="Persistence of Dreams - Sentinels #9 by Matt Wilson"
          name="Persistence of Dreams"
          description="A large cloud, draped in a gold piped cloth moved towards a nearly dead tree in the desert"
          urlExternal="https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/9"
          next="/sentinels/morvan"
          prev="/sentinels/petrichor"
          classN="persistence-of-dreams"
        />
    )
}
