import React, {useState} from "react"
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import Traversal from './Traversal'
import Menu from '../Menu'
import {Modal} from './Modal'
import {motion} from 'framer-motion/dist/framer-motion'

const SentinelComponent = ({ src, alt, name, description, urlExternal, index, next, prev, classN }) => {
    const [showModal, setShowModal] = useState(false);

    const openModal = () => {
      setShowModal(prev => !prev);
    }

    return(
        <div className={classN}>
          <div className="sentinel--container">
            <motion.img initial={{opacity:0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration:0.7, repeatType: "reverse"}} className="sentinel--image" src={src} alt={alt} />

            <div className="sentinel--content">
              <motion.h1 initial={{x:200, opacity:0}} animate={{opacity: 1, x:0}} exit={{opacity: 0}} transition={{duration:1, delay: 0.7, repeatType: "reverse"}}>{name}</motion.h1>
              <motion.p initial={{opacity:0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration:1, delay: 1, repeatType: "reverse"}} className="description" dangerouslySetInnerHTML={{__html: description}}></motion.p>
              <motion.a href={urlExternal} target="_blank" initial={{opacity:0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration:1, delay: 1.4, repeatType: "reverse"}}>View on OpenSea</motion.a>
            </div>

            <div className="sentinel--collection-link view-collection-link">
              <a href="https://opensea.io/collection/sentinels-v3" target="_blank">
                <span className="link-text">View Sentinels <br />On OpenSea</span>
                <span><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></span>
              </a>
            </div>

            <div className="sentinels--menu">
              <Modal showModal={showModal} setShowModal={setShowModal} />
            </div>
            <motion.div className="sentinel--controls" initial={{x:-200, opacity:0}} animate={{opacity: 1, x:0}} exit={{opacity: 0}} transition={{duration:1, delay: 1, repeatType: "reverse"}}>
              <div className="sentinel--number"><h2>Sentinel #{index}</h2></div>
              <div className="controls">
                <motion.div initial={{opacity:0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration:1, delay: 2, repeatType: "reverse"}} className="prev"><a href={prev}>Previous</a></motion.div>
                <motion.div initial={{opacity:0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration:1, delay: 2.3, repeatType: "reverse"}} className="view-all" onClick={openModal}>View All</motion.div>
                <motion.div initial={{opacity:0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration:1, delay: 2.6, repeatType: "reverse"}} className="next"><a href={next}>Next</a></motion.div>
              </div>
            </motion.div>
          </div>
        </div>
    )
};

export default SentinelComponent;
