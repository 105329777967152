import React, { useEffect } from "react"
import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { faTwitter, faInstagram, faYoutube, faVimeo } from '@fortawesome/free-brands-svg-icons'
import ContactForm from './ContactForm'

export default function Contact(){
    useEffect(() => {
      if(document.body.classList.contains('show')) {
        document.body.classList.remove("show");
      }
      document.body.classList.add("hidden");
      document.body.classList.add("contact");

      if(document.getElementsByTagName('footer')[0].classList.contains('relative')) {
        document.getElementsByTagName('footer')[0].classList.remove("relative");
      } else if(document.getElementsByTagName('footer')[0].classList.contains('fixed')) {
        document.getElementsByTagName('footer')[0].classList.remove("fixed");
      }

      return () => {
        document.body.classList.remove("contact");
      };
    }, []);
    return (
        <div className="mainContent contact">
          <div className="content-center">
            <div className="content-left">
              <h1>Get in touch with <span>Matt Wilson</span> <br /><span>& his team</span></h1>
              <div className="contact-info">
                <a href="mailto:management@bunnylever.io">management@bunnylever.io</a>
                <ul className="social">
                  <li><a target="_blank" href="https://twitter.com/bunnylever"><FontAwesomeIcon icon={faTwitter}>Twitter</FontAwesomeIcon></a></li>
                  <li><a target="_blank" href="https://www.instagram.com/bunnylever/?hl=en"><FontAwesomeIcon icon={faInstagram}>Instagram</FontAwesomeIcon></a></li>
                  <li><a target="_blank" href=""><FontAwesomeIcon icon={faVimeo}>Vimeo</FontAwesomeIcon></a></li>
                  <li><a target="_blank" href=""><FontAwesomeIcon icon={faYoutube}>YouTube</FontAwesomeIcon></a></li>
                </ul>
              </div>
            </div>
            <div className="content-right">
              <ContactForm />
            </div>
          </div>
        </div>
    )
}
