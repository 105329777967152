import React from "react"
import SentinelComponent from "./SentinelComponent"
import traversal from "../../../images/traversal.png"

export default function Traversal(){
    return(
        <SentinelComponent
          index="1"
          link="/sentinels/traversal"
          src={traversal}
          alt="Traversal - Sentinels #1 by Matt Wilson"
          name="Traversal"
          description="Please…sit, rest. Speak & be heard. Allow me to introduce you to a mother, father, son, daughter, sister, brother, partner, wild child, friend and listener. The world might seem barren and scorched at times, but here in the shade where these many Sentinels traverse and many paths cross, may you find solace and a place to be renewed."
          urlExternal="https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/1"
          next="/sentinels/solace-and-discovery"
          prev="/sentinels/maelstrom"
          classN="traversal"
        />
    )
}
