import React from "react"
import SentinelComponent from "./SentinelComponent"
import sleepingGiants from "../../../images/sleeping-giants.jpg"

export default function SleepingGiants(){
    return(
        <SentinelComponent
          index="11"
          link="/sentinels/sleeping-giants"
          src={sleepingGiants}
          alt="Sleeping Giants - Sentinels #11 by Matt Wilson"
          name="Sleeping Giants"
          description="Several cloud formations, covered in cloth hover over the ocean"
          urlExternal="https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/11"
          next="/sentinels/maelstrom"
          prev="/sentinels/morvan"
          classN="sleeping-giants"
        />
    )
}
