import React from "react"
import clouds from '../images/404-clouds.jpg'
import {Link} from "react-router-dom";
import {motion} from 'framer-motion/dist/framer-motion'

function Error() {
  return (
    <div>
      <motion.img src={clouds} initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{duration: 0.3}} className="error-image" alt="Page not found - 404 error" />
      <div class="error-overlay"></div>
      <div class="error-content">
        <motion.h2 initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{repeatType: "reverse", duration: 1, delay: 1.5}}>The only thing here is clouds</motion.h2>
        <h1>
          <motion.span initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{repeatType: "reverse", duration: 1}}>4</motion.span>
          <motion.span initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{repeatType: "reverse", duration: 1, delay: 0.4}}>0</motion.span>
          <motion.span initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{repeatType: "reverse", duration: 1, delay: 0.8}}>4</motion.span>
        </h1>
        <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{repeatType: "reverse", duration: 1, delay: 1.2}}>
          <Link to="/">Fly back home</Link>
        </motion.div>
      </div>
    </div>
  );
}

export default Error;
