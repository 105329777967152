import React from "react"
import SentinelComponent from "./SentinelComponent"
import solaceAndDiscovery from "../../../images/solace-and-discovery.png"

export default function SolaceAndDiscovery(){
    return(
        <SentinelComponent
          index="2"
          link="/sentinels/solace-and-discovery"
          src={solaceAndDiscovery}
          alt="Solace & Discovery - Sentinels #2 by Matt Wilson"
          name="Solace & Discovery"
          description="We recreate ourselves many times during our lives, constantly pulling down barriers in search of the truth. Perhaps we are fortunate in that we can still recognize the way. Both the route and the walls define us in these cycles. However, when we choose to think outside the box and seek to see potential in its genuine form, we discover that it is actually shapeless."
          urlExternal="https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/2"
          next="/sentinels/arise"
          prev="/sentinels/traversal"
          classN="solaceAndDiscovery"
        />
    )
}
