import React from "react"
import {Link} from "react-router-dom";
import {sentinelsCollection} from "./collection/SentinelsData"

const Menu = ({  }) => {

  return (
    <ul className="">
      {sentinelsCollection.map((item, index) => (
        <li key={index}>
          <Link to={item.url}>
            <div>
              <img src={item.image} alt={item.alt} />
              <span>Sentinels #{index+1}</span>
            </div>
          </Link>
        </li>
      ))}
    </ul>

  );
};

export default Menu;
