import React, { Component, useRef, useEffect } from 'react'
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion'
import MyComponent from 'react-full-page-scroller'
import clouds from "../../images/clouds-image.png"
import {Link} from "react-router-dom";
import Menu from "./Menu"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"


export default function Fullpage() {
    const scrollToTop = useRef(null);

    useEffect(() => {
      //scrollToTop.current.scrollIntoView()
      console.log(scrollToTop)

      return () => {
        // this runs when you leave the page
      };
    }, []);


    return (
      <MyComponent>
        <div ref={scrollToTop} className="screen--container sentinels--screen1">
          <motion.div initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:3}}>
            <h1>Sentinels</h1>
            <p>sen·ti·nel /ˈsent(ə)nəl/ A soldier or guard whose job is to stand and keep watch. We are the guardians of our world, our dreams, our memories. </p>
          </motion.div>
          <div className="view-collection-link">
            <a rel="noreferrer noopener" href="https://opensea.io/collection/sentinels-v3" target="_blank">
              <span className="link-text">View Sentinels <br />On OpenSea</span>
              <span><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></span>
            </a>
          </div>
        </div>
        <div className="screen--container sentinels--screen2">
          <motion.div initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:3}}>
            <h2>Travelers <br /><span>Sentinels.</span></h2>
          </motion.div>
          <div className="view-collection-link">
            <a href="https://opensea.io/collection/sentinels-v3" target="_blank">
              <span className="link-text">View Sentinels <br />On OpenSea</span>
              <span><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></span>
            </a>
          </div>
        </div>
        <div className="screen--container sentinels--screen3">
          <motion.div initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:3}}>
            <p>Despite the various forms expressed throughout our lives, we maintain a notion of self. Yet the memories often contain a version of us, perhaps strange or foreign to the now.</p>
            <p>Just as a cloud is seen as a novel form when viewed from many different perspectives, the framing of our experiences helps to establish who we are, and guide the forces that grow and thrust us into our next moments.</p>
          </motion.div>
          <img src={clouds} alt="Matt Wilson cloud" />
          <div className="view-collection-link">
            <a href="https://opensea.io/collection/sentinels-v3" target="_blank">
              <span className="link-text">View Sentinels <br />On OpenSea</span>
              <span><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></span>
            </a>
          </div>
        </div>
        <div className="screen--container sentinels--screen4">
          <motion.div initial={{opacity:0}} whileInView={{opacity:1}} transition={{duration:3}}>
            <p><span>“Sentinels”</span> is an autobiographical and surrealist exploration of the experiences in the life of artist Matt Wilson</p>
          </motion.div>
          <div className="view-collection-link">
            <a href="https://opensea.io/collection/sentinels-v3" target="_blank">
              <span className="link-text">View Sentinels <br />On OpenSea</span>
              <span><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></span>
            </a>
          </div>
        </div>
        <div className="screen--container sentinels--menu">
          <div className="left">
            <span className="helper-msg">Click on a Sentinel to view it</span>
            <Menu />
          </div>
          <div className="right">
            <h3>Menu</h3>
            <h2>Sentinels</h2>
            <p>Each scene, each cloud, is frozen in space & time like a statue in a grand gallery locked away, long forgotten. The worlds that surround them are intended to set the stage for each moment.</p>
          </div>
          <div className="view-collection-link">
            <a href="https://opensea.io/collection/sentinels-v3" target="_blank">
              <span className="link-text">View Sentinels <br />On OpenSea</span>
              <span><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></span>
            </a>
          </div>
        </div>
      </MyComponent>
    )
}
