import React from 'react'
import Menu from '../Menu'

export const Modal = ({showModal, setShowModal}) => {
  return (
    <>
      {showModal ? <><div className="close-modal" onClick={() => setShowModal(prev => !prev)}>X</div><Menu /></> : null}
    </>
  )
}

export default Modal;
