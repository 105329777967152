import React from 'react'

export const NFTPortfolioDescriptionModal = ({showModal, setShowModal, description}) => {
  const update_desc = () => {
    description.replace(/\n/g, "<br />")

    return description;
  }
  return (
    <>
      {showModal ?
        <>
          <div className="description--modal-container">
            <div className="close-modal" onClick={() => setShowModal(prev => !prev)}>X</div>
            <div className="description" dangerouslySetInnerHTML={{__html: update_desc(description)}}></div>
          </div>
        </>
      : null}
    </>
  )
}

export default NFTPortfolioDescriptionModal;
