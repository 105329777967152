import React from "react"
import SentinelComponent from "./SentinelComponent"
import maelstrom from "../../../images/maelstrom.jpg"

export default function Maelstrom(){
    return(
        <SentinelComponent
          index="12"
          link="/sentinels/maelstrom"
          src={maelstrom}
          alt="Maelstrom - Sentinels #12 by Matt Wilson"
          name="Maelstrom"
          description="​A massive storm front rises into the air, clinging to a thin, windswept drape"
          urlExternal="https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/12"
          next="/sentinels/traversal"
          prev="/sentinels/sleeping-giants"
          classN="maelstrom"
        />
    )
}
