import React from 'react';
import { Formik } from 'formik';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

 const ContactForm = () => (
   <>
     <Formik
       initialValues={{ name: '', message: '', email: '' }}
       validate={values => {
         const errors = {};

         if(!values.name) {
           errors.name = 'Required'
         }

         if(!values.message) {
            errors.message = 'Required'
         }

         if (!values.email) {
           errors.email = 'Required';
         } else if (
           !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
         ) {
           errors.email = 'Invalid email address';
         }
         return errors;
       }}
       onSubmit={(values, { setSubmitting }) => {
         setTimeout(() => {
           alert(JSON.stringify(values, null, 2));
           setSubmitting(false);
         }, 400);
       }}
     >
       {({
         values,
         errors,
         touched,
         handleChange,
         handleBlur,
         handleSubmit,
         isSubmitting,
         /* and other goodies */
       }) => (
         <form onSubmit={handleSubmit}>
            <p>Hi, my name is
              <input
                type="text"
                name="name"
                placeholder="Your name here"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.name}
              />
              {errors.name && touched.name && errors.name}
            </p>
            <p>I am looking to contact Matt Wilson (BunnyLever) & his team regarding:
              <textarea
                name="message"
                placeholder="Type your message here"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
              >
              </textarea>
              {errors.message && touched.message && errors.message}
            </p>
           <p>You can reach me at
            <input
              type="email"
              name="email"
              placeholder="Your email here"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
            />
            {errors.email && touched.email && errors.email}
            </p>
           <button type="submit" disabled={isSubmitting}>
           Send Inquiry
           <FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
           </button>
         </form>
       )}
     </Formik>
   </>
 );

 export default ContactForm;
