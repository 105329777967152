import React from "react"
import SentinelComponent from "./SentinelComponent"
import passage from "../../../images/passage.png"

export default function Passage(){
    return(
        <SentinelComponent
          index="5"
          link="/sentinels/passage"
          src={passage}
          alt="Passage - Sentinels #5 by Matt Wilson"
          name="Passage"
          description="​Deep in the darkness of Joshua tree, we found a passage in the stars. Sitting there in the cold, together under the light of the Milky way, we laugh and cried and dreamt of the future. We hadn’t realized it then, but his little feet were just on the edge of life. There he stood, listing to the story of our lives together, waiting to become part of it. Waiting for his turn to take our flame and become the guardian of his destiny."
          urlExternal="https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/5"
          next="/sentinels/mirror"
          prev="/sentinels/juniper"
          classN="passage"
        />
    )
}
