import React, { useState, useRef, useCallback } from "react"
import logo from "../images/matt-wilson-logo-white.png"
import { BrowserRouter as Router, Routes, Route, Link, ActiveStyle, useLocation } from "react-router-dom"

export default function Header(){

    const [isVisible, setVisible] = useState(true);

    const location = useLocation();

    if(location.pathname != '/about') {
      document.getElementsByTagName('body')[0].style.background = "#1A1A1A";
    }

    const ref = useRef(null);

    const handleChange = useCallback(() => {
      //console.log(ref);
      if (ref.current.checked) {
        //console.log('✅ Checkbox is checked');
        ref.current.checked = false;
      } else {
        //console.log('⛔️ Checkbox is NOT checked');
        ref.current.checked = true;
      }
    });

    return(
        <header>
            <div className="logo">
              <Link to="/">
                <img src={logo} alt="Matt Wilson Logo" />
                <div className="name">
                  Matt Wilson
                  <p>BunnyLever</p>
                </div>
              </Link>
            </div>
            <div className="menu">
              <input type="checkbox" className="menu-icon" id="menu-icon" name="menu-icon" ref={ref} />
              <label htmlFor="menu-icon"></label>
              <nav className="nav">
                <ul>
                  <div className="enter-sentinels" onClick={handleChange}><Link to="/sentinels">Enter Sentinels</Link></div>
                  <li onClick={handleChange}><Link to="/">Home</Link></li>
                  <li onClick={handleChange}><Link to="/about">About</Link></li>
                  <li onClick={handleChange}><Link to="/portfolio">Portfolio</Link></li>
                  <li onClick={handleChange}><Link to="/contact">Contact</Link></li>
                </ul>
              </nav>
            </div>
         </header>
    )
}
