import React, { useEffect, useState, useRef } from "react"
import mouseIcon from "../../images/mouse-icon.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import traversal from "../../images/traversal.png"
import solaceAndDiscovery from "../../images/solace-and-discovery.png"
import arise from "../../images/arise.png"
import junpier from "../../images/juniper.png"
import mirror from "../../images/mirror.png"
import passage from "../../images/passage.png"
import fortyForthAndSecond from "../../images/44th-and-2nd.jpeg"
import maelstrom from "../../images/maelstrom.jpeg"
import sleepingGiants from "../../images/sleeping-giants.jpeg"
import morvan from "../../images/morvan.jpeg"
import petrichor from "../../images/petrichor.jpeg"
import persistenceOfDreams from "../../images/persistence-of-dreams.jpeg"
import {sentinelsCollection} from "../sentinels/collection/SentinelsData"
import NFTPortfolioItem from "./NFTPortfolioItem"
import {motion} from 'framer-motion/dist/framer-motion'

export default function NFT(){

    const [width, setWidth] = useState(0);
    const carousel = useRef();

    useEffect(() => {
      setWidth(carousel.current.scrollWidth - carousel.current.offsetWidth);

      return () => {
      };
    }, []);

    return (
        <div className="mainContent portfolio nft">
          <motion.div ref={carousel} className="carousel">
            <motion.ul className="inner-carousel items" drag="x" dragConstraints={{right: 0, left: -width}}>
              {sentinelsCollection.map((item, index) => (
                <NFTPortfolioItem key={index} item={item} />
              ))}
            </motion.ul>
          </motion.div>

          <div className="enter-sentinels-link">
            <img src={mouseIcon} alt="Enter Sentinels" />
            <p>Grab & Drag to see the rest of Matt's NFT portfolio</p>
          </div>
        </div>
    )
}
