import React, {useState} from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import {motion} from 'framer-motion/dist/framer-motion'
import {Link} from "react-router-dom";
import NFTPortfolioDescriptionModal from './NFTPortfolioDescriptionModal'

const NFTPortfolioItem = ({ item }) => {
  const { url, image, alt, price, marketplace_link, type, name, description } = item;

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(prev => !prev);
  }

  return (
    <motion.li className="item" key={image}>
      <div className="image-container">
        <img src={image} alt={alt} />
      </div>

      <div className="item--content">
        <h2 className="nft-name"><Link to={url}>{name}</Link></h2>
        <h3 className="nft-count">{type}</h3>
        <div className="nft-price">{price}</div>

        <div className="item--links">
          <div className="view-description" onClick={openModal}>View Description</div>
          <div className="view-link-outer">
            <a target="_blank" href={marketplace_link} className="view-link">
              <span>View on <br />OpenSea</span>
              <span><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></span>
            </a>
          </div>
        </div>
      </div>

      <NFTPortfolioDescriptionModal showModal={showModal} setShowModal={setShowModal} description={description} />
    </motion.li>
  );
};

export default NFTPortfolioItem;
