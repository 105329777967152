import img1 from "../../images/web2/Fox-Searchlight-Pictures.png"
import img2 from "../../images/web2/2.jpg"
import img3 from "../../images/web2/3.jpg"
import img4 from "../../images/web2/4.jpg"
import img5 from "../../images/web2/5.jpg"

export const webTData = [
  {
    src: img1
  },
  {
    src: img2
  },
  {
    src: img3
  },
  {
    src: img4
  },
  {
    src: img5
  }
];
