import React, {useState, useRef, useEffect} from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { faTwitter, faInstagram, faYoutube, faVimeo } from '@fortawesome/free-brands-svg-icons'
import {motion} from 'framer-motion/dist/framer-motion'
import { useLocation } from "react-router-dom"

export default function Footer(){

    {/*
    const footerClass = useRef(null);

    useEffect(() => {
        const ev = footerClass.current
        console.log(ev)
        console.log('fuck')
        if(location.pathname == '/about') {
          if(ev.classList.contains('fixed')) {
            ev.classList.remove('fixed')
          }
          ev.classList.add('relative')
        } else if(location.pathname == '/sentinels') {
        if(ev.classList.contains('relative')) {
            ev.classList.remove('relative')
          }
          ev.classList.add('fixed')
        } else {
           //empty class list to use default styles in style.css
          if(ev.classList.contains('fixed')) {
            ev.classList.remove('fixed')
          } else if(ev.classList.contains('relative')) {
            ev.classList.remove('relative')
          }
        }

      return () => {
      };
    }, [location]);
    */}
    const [isVisible, setVisible] = useState(true);
    const location = useLocation();


    return(
        <motion.footer inital={{opacity:1}} exit={{opacity:0}}>
            <div>
                <p>
                  <span>As &nbsp; seen &nbsp; on</span><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon>
                  <ul className="social">
                    <li><a target="_blank" href="https://twitter.com/bunnylever"><FontAwesomeIcon icon={faTwitter}>Twitter</FontAwesomeIcon></a></li>
                    <li><a target="_blank" href="https://www.instagram.com/bunnylever/?hl=en"><FontAwesomeIcon icon={faInstagram}>Instagram</FontAwesomeIcon></a></li>
                    <li><a target="_blank" href=""><FontAwesomeIcon icon={faVimeo}>Vimeo</FontAwesomeIcon></a></li>
                    <li><a target="_blank" href=""><FontAwesomeIcon icon={faYoutube}>YouTube</FontAwesomeIcon></a></li>
                  </ul>
                </p>
                <p className="copyright">All rights reserved</p>
            </div>
            <div className="copy-right">
                <p className="created-by">The official site of Matt Wilson<br /> designed & developed by</p>
                <h4 className="1studios"><a href="https://1studio.io">1Studios</a></h4>
            </div>
        </motion.footer>
    )
}
