import React, { useEffect, useState, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import {AnimatePresence, motion} from 'framer-motion/dist/framer-motion'
import {useLocation, Link} from "react-router-dom";
import clouds1 from "../../images/clouds-1.svg"
import clouds2 from "../../images/clouds-2.svg"
import clouds3 from "../../images/clouds-3.svg"
import juniper from "../../images/juniper.png"
import Fullpage from './Fullpage'

export default function Sentinels(){

    useEffect(() => {
      if(document.body.classList.contains('hidden')) {
        document.body.classList.remove("hidden");
      }
      if(!document.body.classList.contains("show"))
        document.body.classList.add("show"); // make page scrollable (most pages are fixed height to viewport)

      document.getElementsByClassName('content')[0].style.margin = 0;

      if(document.getElementsByTagName('footer')[0].classList.contains('relative')) {
          document.getElementsByTagName('footer')[0].classList.remove("relative");
      }
      document.getElementsByTagName('footer')[0].classList.add("fixed");

      return () => {
        // this runs when you leave the page
      };
    }, []);

    return (
      <>
        <div className="sentinels--loading">
          <img src={juniper} alt="Juniper" />
          <div className="overlay"></div>
        </div>
        <Fullpage />
      </>
    )
}
