import React, { useEffect, useState } from "react"
import clouds from "../images/clouds-image.png"
import clouds1 from "../images/clouds-1.svg"
import clouds2 from "../images/clouds-2.svg"
import clouds3 from "../images/clouds-3.svg"
import mouseIcon from "../images/mouse-icon.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import {motion} from 'framer-motion/dist/framer-motion'
import {useLocation, Link} from "react-router-dom";

export default function MainContent(){
    useEffect(() => {
      if(document.body.classList.contains('show')) {
        document.body.classList.remove("show");
      }
      document.body.classList.add("hidden");

      if(document.getElementsByTagName('footer')[0].classList.contains('relative')) {
        document.getElementsByTagName('footer')[0].classList.remove("relative");
      } else if(document.getElementsByTagName('footer')[0].classList.contains('fixed')) {
        document.getElementsByTagName('footer')[0].classList.remove("fixed");
      }

      return () => {
      };
    }, []);

    const line1 = "Exploring the beauty of clouds";
    const sentence = {
      hidden: {opacity: 1},
      visible: {
        opacity: 1,
        transition: {
          delay: 0,
          staggerChildren: 0.1,
        },
      },
    };

    const letter = {
      hidden: {opacity: 0, y: 100},
      visible: {
        opacity: 1,
        y: 0,
      },
    };

    return (

        <motion.div className="mainContent">
          <div className="background-images">
            <motion.img initial={{opacity: 0}} animate={{opacity:1}} transition={{duration: 1, delay:0.3}} exit={{opacity:0}} className="clouds" src={clouds} />
          </div>
          <div className="content-center">
            <motion.h1
              className="load-screen--message home-transition-1"
              variants={sentence}
              initial="hidden"
              animate="visible"
              exit={{opacity:0}}>
              {line1.split("").map((char,index) => {
                return (
                  <motion.span key={char + '-' + index} variants={letter}>{char}</motion.span>
                )
              })}
            </motion.h1>
          </div>

          <motion.div
            initial={{opacity: 0}}
            animate={{opacity:1}}
            transition={{duration:1}}
            exit={{y:600}}
            className="view-link-outer">
            <a target="_blank" href="https://opensea.io/collection/sentinels-v3" className="view-link">
              <span>View Sentinels on <br />OpenSea</span>
              <span><FontAwesomeIcon icon={faAngleRight}></FontAwesomeIcon></span>
            </a>
          </motion.div>

          <motion.div
            initial={{opacity: 0, scale:0}}
            animate={{opacity:1, scale:1}}
            exit={{y:-350, x:500, scale:0}}
            transition={{duration:1}}
            className="moving-clouds clouds-1">
              <Link to="/sentinels">
                <img className="moving-clouds clouds-1" src={clouds1} />
              </Link>
          </motion.div>
          <motion.div initial={{opacity: 0, scale:0}} animate={{opacity:1, scale:1}} exit={{y:650, x:200, scale:0}} transition={{duration:1.2}} className="moving-clouds clouds-2"><Link to="/sentinels"><img className="moving-clouds clouds-2" src={clouds2} /></Link></motion.div>
          <motion.div initial={{opacity: 0, scale:0}} animate={{opacity:1, scale:1}} exit={{scale:10, zIndex:10000}} transition={{duration:1.4}} className="moving-clouds clouds-3"><Link to="/sentinels"><img className="moving-clouds clouds-3" src={clouds3} /></Link></motion.div>

          <Link to="/sentinels">
          <motion.div initial={{opacity: 0}} animate={{opacity:1}} exit={{y:600}} transition={{duration:1}} className="enter-sentinels-link">
            <img src={mouseIcon} alt="Enter Sentinels" />
            <p>Click here or on the clouds to enter <strong>Sentinels</strong></p>
          </motion.div>
          </Link>
        </motion.div>

    )
}
