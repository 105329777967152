import React from "react"
import SentinelComponent from "./SentinelComponent"
import mirror from "../../../images/mirror.png"

export default function Mirror(){
    return(
        <SentinelComponent
          index="6"
          link="/sentinels/mirror"
          src={mirror}
          alt="Mirror - Sentinels #5 by Matt Wilson"
          name="Mirror"
          description="Mad Song, <br />
                      By William Blake <br />
<br />
                      “The wild winds weep <br />
                      And the night is a-cold; <br />
                      Come hither, Sleep, <br />
                      And my griefs infold:<br />
                      But lo! the morning peeps<br />
                      Over the eastern steeps,<br />
                      And the rustling birds of dawn<br />
                      The earth do scorn.<br />
<br />
                      Lo! to the vault<br />
                      Of paved heaven,<br />
                      With sorrow fraught<br />
                      My notes are driven:<br />
                      They strike the ear of night,<br />
                      Make weep the eyes of day;<br />
                      They make mad the roaring winds,<br />
                      And with tempests play.<br />
<br />
                      Like a fiend in a cloud,<br />
                      With howling woe,<br />
                      After night I do crowd,<br />
                      And with night will go;<br />
                      I turn my back to the east,<br />
                      From whence comforts have increas’d;<br />
                      For light doth seize my brain<br />
                      With frantic pain."
          urlExternal="https://opensea.io/assets/ethereum/0x6d328544b7fac30427a7f77eaf119b48b58f11ce/6"
          next="/sentinels/44th-and-2nd"
          prev="/sentinels/passage"
          classN="mirror"
        />
    )
}
