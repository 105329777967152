import React, { useEffect } from 'react'

let slideCounter = 0;

const useSlider = (slideImage, nextImage, images) => {

 useEffect(() => {
   startSlider()

   return () => {
   };
 }, []);

const startSlider = (event) => {
  slideCounter = 0

  slideImage.current.style.backgroundImage = `linear-gradient(
      to right,
      rgba(34, 34, 34, 0.4),
      rgba(68, 68, 68, 0.4)
      ), url(${images[0].src})`;
  nextImage.current.style.backgroundImage = `linear-gradient(
      to right,
      rgba(34, 34, 34, 0.4),
      rgba(68, 68, 68, 0.4)
      ), url(${images[1].src})`;
}

const resetNextSlide =() => {
  nextImage.current.style.backgroundImage = `linear-gradient(
      to right,
      rgba(34, 34, 34, 0.4),
      rgba(68, 68, 68, 0.4)
    ), url(${images[0].src})`;
}

const resetPrevSlide =() => {
  nextImage.current.style.backgroundImage = `linear-gradient(
      to right,
      rgba(34, 34, 34, 0.4),
      rgba(68, 68, 68, 0.4)
    ), url(${images[images.length-1].src})`;
}

const handleSlide = slide => {
  slideImage.current.style.backgroundImage = `linear-gradient(
    to right,
    rgba(34, 34, 34, 0.4),
    rgba(68, 68, 68, 0.4)
    ), url(${images[slide - 1].src})`

  if(slide === images.length) {
    nextImage.current.style.backgroundImage = `linear-gradient(
      to right,
      rgba(34, 34, 34, 0.4),
      rgba(68, 68, 68, 0.4)
    ), url(${images[0].src})`
  } else {
    nextImage.current.style.backgroundImage = `linear-gradient(
      to right,
      rgba(34, 34, 34, 0.4),
      rgba(68, 68, 68, 0.4)
    ), url(${images[slide].src})`
  }

  animateSlide(slideImage)
}

const animateSlide = () => {
  slideImage.current.classList.add("fadeIn")
  setTimeout(() => {
    slideImage.current.classList.remove("fadeIn")
  }, 700)
}

const animateNextSlide = () => {
  nextImage.current.classList.add("fadeIn")
  setTimeout(() => {
    nextImage.current.classList.remove("fadeIn")
  }, 700)
}

const goToPreviousSlide = () => {
  if (slideCounter === 0) {
    handleSlide(images.length)
    slideCounter = images.length;
  }

    handleSlide(slideCounter)
    slideCounter--;
}

const goToNextSlide = () => {
  console.log(slideCounter)
  if (slideCounter === images.length - 1) {
    startSlider()
    slideCounter = -1;
    animateSlide(slideImage, nextImage)
  }

  if (slideCounter+1 === images.length-1) {
    resetNextSlide()
  } else {
    nextImage.current.style.backgroundImage = `linear-gradient(
       to right,
       rgba(34, 34, 34, 0.4),
       rgba(68, 68, 68, 0.4)
     ),url(${images[slideCounter+2].src})`;
  }

  slideImage.current.style.backgroundImage = `linear-gradient(
        to right,
        rgba(34, 34, 34, 0.4),
        rgba(68, 68, 68, 0.4)
        ),url(${images[slideCounter + 1].src})`;

    slideCounter++;
  animateSlide(slideImage)
  animateNextSlide(nextImage)

}

const jumpToSlide = (props) => {
  // close grid view/switch back to full view

  // update slide counter
  slideCounter = props.index

  // update main image
  slideImage.current.style.backgroundImage = `linear-gradient(
    to right,
    rgba(34, 34, 34, 0.4),
    rgba(68, 68, 68, 0.4)
  ),url(${images[props.index].src})`;

  // update next image
  if(props.index === images.length-1) {
    // this is the last image in the gallery, loop back around to show first image as next
    nextImage.current.style.backgroundImage = `linear-gradient(
       to right,
       rgba(34, 34, 34, 0.4),
       rgba(68, 68, 68, 0.4)
     ),url(${images[0].src})`;
  } else {
    nextImage.current.style.backgroundImage = `linear-gradient(
       to right,
       rgba(34, 34, 34, 0.4),
       rgba(68, 68, 68, 0.4)
     ),url(${images[props.index+1].src})`;
  }

  // animate them in
  animateSlide(slideImage)
  animateNextSlide(nextImage)
}

return { goToPreviousSlide, goToNextSlide, jumpToSlide }
}

export default useSlider
