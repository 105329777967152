import React, {useState, useEffect} from "react"
import WebT from "./portfolio/WebT"
import Nft from "./portfolio/NFT"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import bunnylever from "../images/bunnylever-2@2x.png"
import {Link} from "react-router-dom";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Portfolio(){
    useEffect(() => {
      if(document.body.classList.contains('show')) {
        document.body.classList.remove("show");
      }
      document.body.classList.add("hidden");

      if(document.getElementsByTagName('footer')[0].classList.contains('relative')) {
        document.getElementsByTagName('footer')[0].classList.remove("relative");
      } else if(document.getElementsByTagName('footer')[0].classList.contains('fixed')) {
        document.getElementsByTagName('footer')[0].classList.remove("fixed");
      }

      return () => {
      };
    }, []);

    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
    };

    function TabPanel(props: TabPanelProps) {
      const { children, value, index, ...other } = props;

      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
        >
          {value === index && (
            <Box>
              {children}
            </Box>
          )}
        </div>
      );
    }

    return (
        <div>
          <Box className="portfolio-menu">
            <img src={bunnylever} alt="BunnyLever" />
            <Tabs value={value} onChange={handleChange} orientation="vertical">
              <Tab label="Web 2.0" {...a11yProps(0)}>Web 2.0</Tab>
              <Tab label="NFT" {...a11yProps(1)}>NFTs</Tab>
              <Link to="/sentinels">Sentinels <br />Collection</Link>
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <WebT />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Nft />
          </TabPanel>
        </div>
    )
}
