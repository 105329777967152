import React, { useState } from "react"
import Header from "./components/Header"
import Footer from "./components/Footer"
import AnimatedRoutes from "./components/AnimatedRoutes"
import Styles from "./style.css"
import { BrowserRouter as Router } from "react-router-dom"

export default function App(){
    return (
      <Router>
        <div className="main">
          <Header />
          <div className="content">
            <AnimatedRoutes />
          </div>
          <Footer />
        </div>
      </Router>
    )
}
